// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-contact-js": () => import("./../../../src/templates/about-contact.js" /* webpackChunkName: "component---src-templates-about-contact-js" */),
  "component---src-templates-copper-alloys-js": () => import("./../../../src/templates/copper-alloys.js" /* webpackChunkName: "component---src-templates-copper-alloys-js" */),
  "component---src-templates-downloads-js": () => import("./../../../src/templates/downloads.js" /* webpackChunkName: "component---src-templates-downloads-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-landingspage-js": () => import("./../../../src/templates/landingspage.js" /* webpackChunkName: "component---src-templates-landingspage-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-serviceproduct-js": () => import("./../../../src/templates/serviceproduct.js" /* webpackChunkName: "component---src-templates-serviceproduct-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

