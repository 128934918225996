module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brouwer Metaal","short_name":"Brouwer Metaal","start_url":"/","background_color":"#00a7e0","theme_color":"#00a7e0","display":"standalone","icon":"src/img/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"afe6005f9c70afa2934ad67a23df76c0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WJNZTWM","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","defaultCrumb":{"location":{"pathname":">"},"crumbLabel":"Home","crumbSeparator":" > ","crumbStyle":{"color":"#666"},"crumbActiveStyle":{"color":"#DE7B11"}},"useClassNames":true,"usePathPrefix":"/blog"},
    }]
